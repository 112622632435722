import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import FlexibleContent from "../components/flexibleContent"
import FAQsHero from "../components/faqsHero"
import FAQs from "../components/faqs"

export default ({ data, location }) => {
  const {
    databaseId,
    title,
    content,
    excerpt,
    flexibleContent,
    faqFields,
  } = data.siteFields.page

  return (
    <Layout location={ location }>

        <SEO title={title} description={excerpt} />

        <FlexibleContent id={databaseId} content={flexibleContent} />

        <FAQsHero title={ title } content={ content } />

        <FAQs faqs={ faqFields } />    

    </Layout>
  )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
                content(format: RENDERED)
                featuredImage {
                    node {
                        altText
                        title(format: RENDERED)
                        mediaItemUrl
                        slug
                    }
                }
                faqFields {
                    faqSection {
                        title
                        faq {
                            question
                            answer
                        }
                    }
                }
                flexibleContent {
                    ... FlexibleContentFragment
                }
            }
        }
    }
`
