import React, { useState, useEffect } from "react"

import { cloneDeep } from 'lodash'

import SidebarMenu from 'components/sidebarMenu'
import Accordion from 'components/accordion'

export default ({ faqs }) => {
    const _faqs = faqs.faqSection
    const [openFAQGroups, setOpenFAQGroup] = useState([])

    const setFAQOpen = openKey => {
        const _openFAQGroups = cloneDeep(openFAQGroups)
        const indexIfExists = _openFAQGroups.indexOf(openKey)

        if (indexIfExists === -1) {
            _openFAQGroups.push(openKey)
        } else {
            _openFAQGroups.splice(indexIfExists, 1)
        }

        setOpenFAQGroup(_openFAQGroups)
    }

    let qCounter = 0

    const handleScroll = id => {
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset - 120;
        window.scrollTo({ top: y, behavior: 'smooth' });

        return false
    }

    const [menuValue, setMenuValue] = useState('faqs-VisitingPentridge')

    useEffect(_ => {
        const handleScrolling = _ => {
            _faqs.map((row, index) => {
                const faqGroupID = 'faqs-' + row.title.replace(/\s/g, '')

                let faqElement = []
                faqElement[index] = document.getElementById(faqGroupID)

                if (faqElement[index].getBoundingClientRect().top - 130 < 0) {
                    setMenuValue(faqGroupID)
                }

                return row
            })
        }

        window.addEventListener('scroll', handleScrolling)

        return _ => {
            window.removeEventListener('scroll', handleScrolling)
        }
    })

    return (
        <div className="faqs">
            <div className="container">
                <SidebarMenu
                    menuValue={menuValue}
                    noMenuCover
                    handleScroll={handleScroll}
                    chapters={_faqs.map(_faq => {
                        return {
                            label: _faq.title,
                            key: 'faqs-' + _faq.title.replace(/\s/g, '')
                        }
                    })}
                />
                <div className="faqs-main">
                    {_faqs.map((row, index) => {
                        const faq = row.faq
                        const faqGroupID = 'faqs-' + row.title.replace(/\s/g, '')

                        return (
                            <div key={index} className="faqs-group" id={faqGroupID} name={faqGroupID}>
                                <h2 dangerouslySetInnerHTML={{ __html: row.title }} />
                                <Accordion
                                    options={faq.map(faqGroup => {
                                        return {
                                            title: faqGroup.question,
                                            copy: faqGroup.answer
                                        }
                                    })}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
