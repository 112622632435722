import React from "react"

export default ({ title, content }) => {
    
    return (

        <div className="faqs-hero">

            <div className='container'>
                
                <h1 dangerouslySetInnerHTML={{ __html: title }} />

                <div dangerouslySetInnerHTML={{ __html: content }} />
                
            </div>
        
        </div>
        
    )
}
